<template>
  <base-section id="about-our-product">
    <base-section-heading title="Installation">
      SwaVan is available in following browsers. Click in the installation link to add SwaVan in your browser.
    </base-section-heading>
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
          <a
            target="_blank"
            :href="card.link"
          >Installation</a>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Chrome',
          subtitle: 'Browsers',
          text: '',
          link: 'https://chrome.google.com/webstore/detail/swavan/pjoolkhhhagnogkehobklpgmfbhndefo',
          callout: '01',
        },
        {
          title: 'Firefox',
          subtitle: 'Browsers',
          text: '',
          link: 'https://addons.mozilla.org/en-US/firefox/addon/swavan/',
          callout: '02',
        },
        {
          title: 'Microsoft Edge',
          subtitle: 'Browsers',
          text: '',
          link: 'https://microsoftedge.microsoft.com/addons/detail/swavan/dpejjfkjbmgambbkcddimjojiaaogieo',
          callout: '03',
        },
        {
          title: 'Opera',
          subtitle: 'Browsers',
          text: 'Comming soon',
          link: '',
          callout: '04',
        },
      ],
    }),
  }
</script>
